<template>
<v-card-title>
  <span v-if="showDate" class="starling-body font-weight-medium">{{formatDate(mood.timestamp)}}</span>
  <span v-if="showTime" class="starling-body ml-3">{{formatHour(mood.timestamp)}}</span>
  <v-spacer></v-spacer>
  <v-card-actions class="header-icons" v-if="this.$route.name !== 'productPlayer'">
    <v-icon class="edit-action" :class="{'active': activeModalView === 'edit'}" @click.native="toggleModal('edit')" v-if="!isHome">
      edit
    </v-icon>
    <v-icon class="delete-action" :class="{'active': activeModalView === 'delete'}" @click.native="toggleModal('delete')" v-if="!isHome">
      delete
    </v-icon>
  </v-card-actions>
</v-card-title>

</template>

<script>
import * as moment from 'moment';

export default {
  name: 'moodCardHeader',
  props: {
    mood: {
      type: Object,
      required: true,
    },
    toggleModal: {
      type: Function,
      required: true,
    },
    isHome: {
      type: Boolean,
      required: false,
    },
    activeModalView: {
      type: [ String, Boolean ],
      required: true,
    },
  },
  computed: {
    moodWithType() {
      return { ...this.mood, type: 'mood' };
    },
    showDate() {
      return !this.mood.visibleFields || this.mood.visibleFields.some(field => field === 'date');
    },
    showTime() {
      return !this.mood.visibleFields || this.mood.visibleFields.some(field => field === 'time');
    },
  },
  methods: {
    editMood() {
      this.$router.push({ name: 'mood-edit', params: { id: this.mood.id } });
    },
    formatDate(timestamp) {
      return moment(timestamp).format('LL');
    },
    formatHour(timestamp) {
      return moment(timestamp).format('LT');
    },
  },
};

</script>
<style scoped>
h5 {
  text-transform: capitalize;
}
</style>
