<template>
<v-card class="mood-card">
  <mood-card-header
          :mood="mood"
          :toggleModal="toggleModal"
          :activeModalView="activeModalView"
          :isHome="isHome">
  </mood-card-header>
  <v-divider light></v-divider>

  <mood-card-modal
          :mood="mood"
          :modalActive="modalActive"
          :activeModalView="activeModalView"
          :closeModal="closeModal"
          class="scale-in-center"
          @edit="onEdit"
          @delete="onDelete">
  </mood-card-modal>

  <mood-card-content
          :mood="mood"
          :isHome="isHome"
          :class="{hidden: modalActive}"
          class="scale-in-center">
  </mood-card-content>
</v-card>
</template>

<script>
import moodCardHeader from '@/views/tools/moods/components/moodCard/mood-card-header.vue';
import moodCardModal from '@/views/tools/moods/components/moodCard/moodCardModal/mood-card-modal.vue';
import moodCardContent from '@/views/tools/moods/components/moodCard/mood-card-content.vue';

export default {
  name: 'moodCard',
  components: {
    moodCardHeader,
    moodCardModal,
    moodCardContent,
  },
  props: {
    mood: {
      type: Object,
      required: true,
    },
    isHome: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      activeModalView: false,
      modalActive: false,
    };
  },
  methods: {
    toggleModal(viewName) {
      if (this.$route.name !== 'moodsAction') {
        if (this.activeModalView === viewName) {
          this.activeModalView = false;
          this.modalActive = false;
        } else {
          this.activeModalView = viewName;
          this.modalActive = true;
        }
      }
    },
    closeModal() {
      this.modalActive = false;
      this.activeModalView = false;
    },
    onDelete() {
      this.$emit('delete', this.mood);
    },
    onEdit() {
      this.$emit('edit', this.mood);
    },
  },
};
</script>

<style scoped>
.mood-card {
  border-radius: 15px;
}
</style>
