/* eslint-disable no-unused-vars */
// don't change the order below
export enum MoodTypeEnum {
  WORRIED = 'worried',
  SAD = 'sad',
  ANXIOUS = 'anxious',
  STRESSED = 'stressed',
  GUILT = 'guilt',
  ANGRY = 'angry',
  RELAXED = 'relaxed',
  CONFIDENT = 'confident',
  HAPPY = 'happy',
}
/* eslint-enable no-unused-vars */

export type MoodType = {
  /* eslint-disable-next-line no-unused-vars */
  [key in MoodTypeEnum]?: {
    selected: boolean,
    value: number | null,
  }
}

export interface IMoodTracker {
  id: string,
  reasonLabel?: string,
  reasonText: string,
  favourite: boolean,
  deleted: boolean,
  date?: string,
  timestamp: string,
  moodIntensities?: MoodType,
  time?: string,
  moodDate?: string,
  moodTime?: string,
  visibleFields?: Array<string>,
}

export interface IMoodHistory {
  timestamp: string,
  moodIntensities: MoodType,
}

export interface MoodTrackerState {
  moods: Array<IMoodTracker>,
  moodFormData?: IMoodTracker,
  moodHistory: Array<IMoodHistory>,
}
