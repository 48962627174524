<template>
<span class="card-modal" :class="{[`modal-active ${activeModalView}`]: modalActive}">
  <v-card-text class="pa-2">
    <mood-card-edit
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'edit'"
            :moodId="mood.id"
            :modalActive="modalActive"
            :closeModal="closeModal"
            @action="onAction">
    </mood-card-edit>

    <mood-card-delete
            :class="{['content-active']: modalActive}"
            v-if="activeModalView === 'delete'"
            :moodId="mood.id"
            :closeModal="closeModal"
            @action="onAction">
    </mood-card-delete>
  </v-card-text>
</span>
</template>

<script>
import moodCardEdit from '@/views/tools/moods/components/moodCard/moodCardModal/mood-card-edit.vue';
import moodCardDelete from '@/views/tools/moods/components/moodCard/moodCardModal/mood-card-delete.vue';

export default {
  name: 'moodCardModal',
  components: {
    moodCardEdit,
    moodCardDelete,
  },
  props: [ 'mood', 'activeModalView', 'modalActive', 'closeModal' ],
  methods: {
    onAction(event) {
      this.$emit(this.activeModalView);
    },
  },
};
</script>
