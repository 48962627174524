var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-content" },
    [
      _c("p", { staticClass: "starling-explanatory mb-2" }, [
        _vm._v(_vm._s(_vm.$t("app.tools.mood_tracker.mood_card.edit")))
      ]),
      _c("action-buttons", {
        attrs: {
          primary: {
            label: _vm.$t("common.actions.edit"),
            disabled: false,
            loading: false
          },
          secondary: {
            label: _vm.$t("common.actions.cancel"),
            disabled: false,
            loading: false
          }
        },
        on: {
          "primary-clicked": _vm.sendEditEvent,
          "secondary-clicked": _vm.closeModal
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }