<template>
<v-card-text class="mood-card-content pa-3">
  <v-layout row wrap gap-xs-3>
    <v-flex xs12 v-for="(item, index) in intensitiesArr" :key="index" :class="[item.label, 'is-active']">
      <p class="starling-body font-weight-bold primary--text">{{ $t(`app.tools.mood_tracker.tabs.tool.fields.emotions.${item.label}`) }}</p>

      <slider
        :value="item.intensity.value"
        :min="0"
        :max="10"
        :tick-labels="false"
        readonly>
      </slider>

      <v-layout no-wrap>
        <v-flex xs4 text-left><em class="starling-text font-weight-semi-bold">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.mild') }}</em></v-flex>
        <v-flex xs4 text-center><em class="starling-text font-weight-semi-bold">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.moderate') }}</em></v-flex>
        <v-flex xs4 text-right><em class="starling-text font-weight-semi-bold" style="margin-right:-5px">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.intense') }}</em></v-flex>
      </v-layout>

      <v-layout no-wrap>
        <v-flex xs4 text-left><span class="starling-text font-weight-semi-bold">0</span></v-flex>
        <v-flex xs4 text-right><span class="starling-text font-weight-semi-bold" style="margin-right:-5px">10</span></v-flex>
      </v-layout>
    </v-flex>
  </v-layout>

  <v-layout v-if="showReason" row wrap pt-3>
    <v-flex xs12>
      <p class="starling-body text-wrap">{{mood.reasonText}}</p>
    </v-flex>
  </v-layout>
</v-card-text>
</template>

<script>
import { format } from 'date-fns';
import { MoodTypeEnum } from '@/scripts/store/modules/tools/moods/types';
import Slider from '@/views/components/slider';

export default {
  name: 'moodCardContent',
  components: { Slider },
  props: [ 'mood', 'isHome' ],
  computed: {
    actionLabel() {
      return this.$t('common.actions.viewDetails');
    },
    actionPath() {
      return this.isHome ? '/moods' : ('/mood/' + (format(new Date(this.mood.timestamp), 'MM-dd-yy')));
    },
    intensitiesArr() {
      const orderedMoods = [];
      Object.values(MoodTypeEnum).forEach(type => {
        if (this.mood.moodIntensities[type] && this.mood.moodIntensities[type].selected) {
          orderedMoods.push({ label: type, intensity: this.mood.moodIntensities[type] });
        }
      });
      return orderedMoods;
    },
    showReason() {
      return !this.mood.visibleFields || this.mood.visibleFields.some(field => field === 'reason');
    },
  },
};
</script>
